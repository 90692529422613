/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

        function moduleBoxes() {
            $('.module-boxes').each(function(){
              if ($(this).hasClass('module-boxes-alternate')) {
                var spacing = 35;
              } else {
                var spacing = 12;
              }
              var moduleBox = $(this).position();
              var moduleBoxHeight = $('.module-boxes-block:eq(0) h2', this).height() + 18;
              $('<div class="module-boxes-bar visible-lg"></div>').css({'top': moduleBox.top + spacing, 'height': moduleBoxHeight}).prependTo('.main').animate({opacity:1}, 400);
              $('.module-boxes-bar').addClass('active');
            });          

            $('.module-boxes-block h2 a').each(function(){
              var link = $(this).prop('href');
              $(this).closest('.module-boxes-block').click(function(){
                 window.location.href = link;
              });
            });
        }

        function modulePanels() {
          $('.module-panels').each(function(){
            var moduleBox = $(this).find('.module-panels-header:eq(0) h2').position();
            var moduleBoxHeight = $('.module-panels-header:eq(0) h2', this).height() + 16;
            $('<div class="module-boxes-bar module-panels-bar visible-lg"></div>').css({'height': moduleBoxHeight}).prependTo('.module-panels-container').animate({opacity:1}, 400);
            $('<div class="module-boxes-bar module-panels-bar module-panels-bar-2 visible-lg"></div>').css({'height': moduleBoxHeight}).prependTo('.module-panels-container').animate({opacity:1}, 400);
            $('.module-boxes-bar').addClass('active');
          });          
        }

        function moduleReports(){
          $('.module-reports-list:eq(0)').each(function(){
            var block = $('.module-reports-list:eq(0)');
            var moduleBoxHeight = $('h2:eq(0)', block).outerHeight();
            var moduleBox = $('h2:eq(0)', block).offset();
            if (block.hasClass('purple')) {
              $('<div class="module-boxes-bar module-boxes-bar-purple left visible-lg"></div>').css({'top': moduleBox.top, 'height': moduleBoxHeight}).prependTo('.wrapper').animate({opacity:1}, 400);
            } else {
              $('<div class="module-boxes-bar module-boxes-bar-light visible-lg"></div>').css({'top': moduleBox.top, 'height': moduleBoxHeight}).prependTo('.wrapper').animate({opacity:1}, 400);
            }
          });
        }

        function moduleWallText(){
          $('.module-photo-wall-text-inner:eq(0)').each(function(){
            var block = $('.module-photo-wall-text-inner:eq(0)');
            var moduleBoxHeight = $(block).outerHeight();
            var moduleBox = $(block).offset();
            if (block.hasClass('purple')) {
              $('<div class="module-boxes-bar module-boxes-bar-purple left visible-lg"></div>').css({'top': moduleBox.top, 'height': moduleBoxHeight}).prependTo('.wrapper').animate({opacity:1}, 400);
            } else {
              $('<div class="module-boxes-bar module-boxes-bar-light right visible-lg "></div>').css({'top': moduleBox.top, 'height': moduleBoxHeight}).prependTo('.wrapper').animate({opacity:1}, 400);
            }          
          });
        }

        function modulePhotoOverlay(){
          $('.module-photo-wall-text-inner:eq(0)').each(function(){
            var block = $('.photo-overlay:eq(0)');
            var moduleBoxHeight = $(block).outerHeight();
            var moduleBox = $(block).offset();
            $('<div class="module-boxes-bar module-boxes-bar-light left visible-lg "></div>').css({'top': moduleBox.top, 'height': moduleBoxHeight}).prependTo('.wrapper').animate({opacity:1}, 400);
          });
        }

        function moduleContact(){
          $('.module-contact').each(function(){
            var spacing = 84;
            var block = $('.module-contact-detail', this);
            var moduleBoxHeight = $(block).outerHeight();
            var moduleBox = $(block).position();
            if (block.parent().hasClass('orange')) {
              $('<div class="module-boxes-bar orange left visible-lg"></div>').css({'top': moduleBox.top + spacing, 'height': moduleBoxHeight}).prependTo('.main').animate({opacity:1}, 400);
            } else {
              $('<div class="module-boxes-bar green right visible-lg"></div>').css({'top': moduleBox.top + spacing, 'height': moduleBoxHeight}).prependTo('.main').animate({opacity:1}, 400);
            }          
          });
        }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(".nav-primary").mmenu({

        }, {
          clone: true
        });
        var MMENU = $(".nav-primary").data( "mmenu" );

        $('.hamburger').click(function(){
          if ($(this).hasClass('is-active')){
            MMENU.close();
          } else {
            MMENU.open();            
          }
        });

        MMENU.bind('opened', function () {
          $('.hamburger').addClass('is-active');
        });
        MMENU.bind('closed', function () {
          $('.hamburger').removeClass('is-active');
        });

        $('.module-slider ul').bxSlider({
          mode: 'fade',
          controls:false
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        setTimeout(moduleBoxes, 400);
        setTimeout(modulePanels, 400);
        setTimeout(moduleReports, 400);
        setTimeout(moduleWallText, 400);
        setTimeout(modulePhotoOverlay, 400);
        setTimeout(moduleContact, 400);
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(window).resize(function(){
    $('.module-boxes-bar').remove();
  })  

})(jQuery); // Fully reference jQuery after this point.
